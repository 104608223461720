import React, { ChangeEvent, useCallback, useMemo } from "react";

export const InputWithLabel = (props: {
  inputKey: string;
  label: string;
  value: { value: string | number; error?: string };
  onChange: (key: string, value: string | number) => void;
  type?: "text" | "number" | "tel" | "email";
  placeholder?: string;
}) => {
  const { inputKey, label, value, onChange, type } = props;
  const onChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(inputKey, event.target.value);
    },
    [inputKey, onChange],
  );
  const onWheelInput = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  }, []);
  const inputValue = useMemo(() => {
    if (type === "number" && value.value === 0) {
      return "";
    }
    return value.value;
  }, [value.value]);
  return (
    <div className="flex flex-col" id={`form-${inputKey}`}>
      <div className="mb-2 text-sm-body md:text-small">{label}</div>
      <input
        type={type || "text"}
        className={`p-2 md:p-3 text-sm-body md:text-body rounded md:rounded-md ${value.error ? "border-[2px] border-primary" : "border-[2px] border-black/20 md:border-0"}`}
        value={inputValue}
        onChange={onChangeInput}
        onWheel={onWheelInput}
        placeholder={props.placeholder ?? ""}
      />
      {value.error && (
        <div className="text-primary text-sm-small md:text-small p-[10px]">
          {value.error}
        </div>
      )}
    </div>
  );
};
