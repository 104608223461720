import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Hero } from "../modules/Hero";
import { Story } from "../modules/Story";
import { Section } from "../ui/Section";
import { useDispatch, useSelector } from "react-redux";
import {
  setBackButton,
  setDocumentTitle,
  setScrollToSection,
} from "../../redux/features/ui/uiSlice";
import { Service } from "../modules/Service";
import { Caroussel } from "../modules/Caroussel";
import { createPortal } from "react-dom";
import { RootState } from "../../redux/store";
import { Button } from "../ui/Button";
import { Trans, useTranslation } from "react-i18next";
import { StoryCaroussel } from "../modules/StoryCaroussel";

export const Home = () => {
  const dispatch = useDispatch();
  const { portalContainerReady, navbarHeight, scrollToSection } = useSelector(
    (state: RootState) => state.ui,
  );
  const { i18n, t } = useTranslation();
  const [scrollPos, SetScrollPos] = useState(0);
  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setDocumentTitle(t("document_title_home")));
  }, [dispatch, t]);
  const bikesRef = useRef<HTMLElement | null>(null);
  const buyButtonRef = useRef<HTMLDivElement | null>(null);
  const [buyButtonWidth, SetBuyButtonWidth] = useState(250);
  useLayoutEffect(() => {
    const onScroll = () => {
      SetScrollPos(window.scrollY);
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  useEffect(() => {
    if (buyButtonRef.current) {
      SetBuyButtonWidth(buyButtonRef.current.clientWidth);
    }
  }, [buyButtonRef, i18n.resolvedLanguage]); // redraw when user changes languages
  const onScrollToBikes = useCallback(
    (animated: boolean = true) => {
      if (bikesRef) {
        window.scrollTo({
          top: bikesRef.current.offsetTop - navbarHeight - 20,
          left: 0,
          behavior: animated ? "smooth" : "auto",
        });
      }
    },
    [bikesRef, navbarHeight],
  );
  const storyData = useMemo(() => {
    return [
      {
        image: "1_limmatputzete.jpg",
        text: "story_page_1",
        title: "story_title_1",
      },
      {
        image: "2_sandstrahlen.jpg",
        text: "story_page_2",
        title: "story_title_2",
      },
      {
        image: "3_werkstatt.jpg",
        text: "story_page_3",
        title: "story_title_3",
      },
      { image: "4_final.jpg", text: "story_page_4", title: "story_title_4" },
    ];
  }, []);
  useEffect(() => {
    if (scrollToSection === "bikes") {
      onScrollToBikes(false);
      dispatch(setScrollToSection(null));
    }
  }, [scrollToSection, onScrollToBikes, dispatch]);
  return (
    <div>
      {portalContainerReady &&
        createPortal(
          <div>
            <div
              ref={buyButtonRef}
              className="mb-3 md:mb-0 transition-all duration-300 ease-in-out"
            >
              <Button onClick={() => onScrollToBikes(true)} variant="contained">
                <Trans>buy_bikes</Trans>
              </Button>
            </div>
          </div>,
          document.getElementById("header-button"),
        )}
      <Section id="video">
        <Hero />
      </Section>
      <Section id="story">
        <div className="hidden md:inline">
          <Story items={storyData} />
        </div>
        <div className="inline md:hidden">
          <StoryCaroussel items={storyData} />
        </div>
      </Section>
      <Section id="bikes" refProp={bikesRef}>
        <Caroussel />
      </Section>
      <Service />
    </div>
  );
};
