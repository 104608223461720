import React from "react";
import { Link } from "wouter";
import { Trans, useTranslation } from "react-i18next";
import SocialInstagram from "../assets/images/social_instagram.svg";
import SocialYouTube from "../assets/images/social_youtube.svg";
import SocialFacebook from "../assets/images/social_facebook.svg";

const FooterLink = (
  props: React.PropsWithChildren<{
    href: string;
  }>,
) => {
  const className = "text-white cursor-pointer";
  return props.href.slice(0, 4) === "http" ? (
    <a className={className} href={props.href} target="_blank">
      {props.children}
    </a>
  ) : (
    <Link className={className} href={props.href}>
      {props.children}
    </Link>
  );
};
const SocialLink = (
  props: React.PropsWithChildren<{
    href: string;
    icon: string;
    label: string;
  }>,
) => (
  <a className="text-white cursor-pointer" href={props.href} target="_blank">
    <img className="h-[28px]" src={props.icon} alt={props.label} />
  </a>
);

const linkShop = {
  de: "https://www.ochsnersport.ch/de/shop/",
  fr: "https://www.ochsnersport.ch/fr/shop/",
  it: "https://www.ochsnersport.ch/it/shop/",
};
export const linkPrivacyPolicy = {
  de: "https://www.ochsnersport.ch/de/shop/cp/unternehmen/datenschutz.html",
  fr: "https://www.ochsnersport.ch/fr/shop/cp/unternehmen/protection-des-donnes.html",
  it: "https://www.ochsnersport.ch/it/shop/cp/unternehmen/protezione-die-dati.html",
};
const linkImpressum = {
  de: "https://www.ochsnersport.ch/de/shop/cp/unternehmen/impressum.html",
  fr: "https://www.ochsnersport.ch/fr/shop/cp/unternehmen/mentions-legales.html",
  it: "https://www.ochsnersport.ch/it/shop/cp/unternehmen/informazioni-legali.html",
};

export const Footer = () => {
  const { t, i18n } = useTranslation("translation");
  return (
    <div className="bg-black flex flex-col md:flex-row justify-between items-start md:items-center px-4 md:px-4 lg:px-[120px] pb-6 md:pb-0">
      <ul className="flex flex-col text-sm md:text-body md:flex-row h-[270px] md:h-[80px] md:h-[186px] justify-center md:justify-evenly items-start md:items-center gap-4">
        <li>
          <FooterLink
            href={linkShop[i18n.resolvedLanguage as "de" | "fr" | "it"]}
          >
            {t("about")}
          </FooterLink>
        </li>
        <li>
          <FooterLink href="/order">
            <Trans>order</Trans>
          </FooterLink>
        </li>
        <li>
          <FooterLink href="/faq">
            <Trans>faq</Trans>
          </FooterLink>
        </li>
        <li>
          <FooterLink
            href={
              linkPrivacyPolicy[i18n.resolvedLanguage as "de" | "fr" | "it"]
            }
          >
            <Trans>privacy_policy</Trans>
          </FooterLink>
        </li>
        <li>
          <FooterLink href="/disclaimers">
            <Trans>disclaimers</Trans>
          </FooterLink>
        </li>
        <li>
          <FooterLink
            href={linkImpressum[i18n.resolvedLanguage as "de" | "fr" | "it"]}
          >
            <Trans>impressum</Trans>
          </FooterLink>
        </li>
      </ul>
      <ul className="flex h-[80px] md:h-[186px] justify-start md:justify-evenly items-center gap-[36px] md:mx-4">
        <li>
          <SocialLink
            href="https://www.instagram.com/ochsner_sport/"
            icon={String(SocialInstagram)}
            label="Instagram"
          />
        </li>
        <li>
          <SocialLink
            href="https://www.youtube.com/user/OchsnerSport"
            icon={String(SocialYouTube)}
            label="YouTube"
          />
        </li>
        <li>
          <SocialLink
            href="https://www.facebook.com/ochsnersport/"
            icon={String(SocialFacebook)}
            label="Facebook"
          />
        </li>
      </ul>
    </div>
  );
};
