import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bike } from "../../redux/features/bike/bikeSlice";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Countdown } from "../ui/Countdown";
import { DataSheet } from "./DataSheet";
import { useSwiper } from "swiper/react";
import { getTranslationKey, useTitle, useWidth } from "../../utils";
import { useGetBikesQuery, useGetTimeQuery } from "../../redux/api";
import { Link } from "wouter";
import { ArrowIcon } from "../ui/ArrowIcon";

const Slide = (props: {
  bike: Bike;
  containerWidth: number;
  hideInfos: boolean;
  currentBike: boolean;
  portrait: boolean;
}) => {
  const { bike, containerWidth, hideInfos, currentBike, portrait } = props;
  return (
    <div
      className="overflow-hidden rounded md:rounded-md relative mx-auto"
      style={{ width: containerWidth }}
    >
      {bike[portrait ? "images_portrait" : "images"].length > 0 && (
        <Link href={`/${bike.id}`}>
          <img
            className="w-full h-full object-cover object-center transition-opacity ease-linear"
            src={bike[portrait ? "images_portrait" : "images"][0].bike_url}
            alt={bike.title}
          />
        </Link>
      )}
      <div
        className={`w-full flex justify-between items-end p-4 absolute bottom-0 left-0 right-0 gap-3 ${hideInfos || bike.id !== bike.id ? "opacity-0" : "opacity-100"}`}
      >
        {bike.highest_bid && currentBike && (
          <div className="bg-black/30 rounded md:rounded-md">
            <div className="text-sm-btn3 md:text-btn text-white pt-[10px] pb-[6px] px-[22px]">
              {bike.is_biddable === false ? (
                <Trans
                  tOptions={{
                    value: Math.round(bike.highest_bid.value),
                    forename: bike.highest_bid.person.forename,
                    name: bike.highest_bid.person.name,
                    city: bike.highest_bid.person.city,
                  }}
                  components={{ bold: <span className="font-bold ml-1" /> }}
                >
                  sold
                </Trans>
              ) : (
                <Trans
                  tOptions={{
                    value: Math.round(bike.highest_bid.value),
                    forename: bike.highest_bid.person.forename,
                    name: bike.highest_bid.person.name,
                    city: bike.highest_bid.person.city,
                  }}
                  components={{ bold: <span className="font-bold ml-1" /> }}
                >
                  current_price
                </Trans>
              )}
            </div>
          </div>
        )}
        <div />
        <div
          className={`hidden md:inline ${currentBike ? "opacity-100" : "opacity-0"}`}
        >
          <Button variant="contained" href={{ url: `/${bike.id}` }}>
            {bike.is_biddable === true ? (
              <Trans>bid</Trans>
            ) : (
              <Trans>more</Trans>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
const SwiperButton = (props: { direction: "next" | "prev" }) => {
  const { direction } = props;
  const swiper = useSwiper();
  const onSwiperNext = useCallback(() => {
    if (swiper) {
      if (props.direction === "next") {
        swiper.slideNext();
      }
      if (direction === "prev") {
        swiper.slidePrev();
      }
    }
  }, [swiper, direction]);
  return (
    <button
      onClick={onSwiperNext}
      className={`absolute top-1/2 mt-[-38.5px] ${direction === "next" ? "right-[146px]" : "left-[146px] rotate-180"} text-white z-[90] bg-black/20 hover:bg-black/60 w-[77px] h-[77px] flex justify-center items-center rounded-full backdrop-blur-lg`}
    >
      <ArrowIcon />
    </button>
  );
};

export const Caroussel = () => {
  const { data: time } = useGetTimeQuery(null, {
    pollingInterval: 1000 * 20,
  });
  const pollingInterval = useMemo(() => {
    return time ? time.polling_interval : 20;
  }, [time]);
  const {
    data: bikes,
    error,
    isLoading,
  } = useGetBikesQuery(null, {
    pollingInterval: 1000 * pollingInterval,
  });
  const { i18n } = useTranslation();
  const [currentBike, setCurrentBike] = useState<Bike | null>(null);
  useEffect(() => {
    if (currentBike === null && bikes) {
      setCurrentBike(bikes[0]);
    } else {
      if (currentBike) {
        const id = currentBike.id;
        setCurrentBike(bikes.find((b) => b.id === id));
      }
    }
  }, [bikes, currentBike]);
  const viewdBikes = useMemo(() => {
    if (bikes && bikes.length != 1 && bikes.length <= 4) {
      return [...bikes, ...bikes];
    }
    return bikes;
  }, [bikes]);
  const onSliderChange = useCallback(
    (swiper) => {
      if (viewdBikes.length > 0) {
        const bike = viewdBikes[swiper.realIndex];
        setCurrentBike(bike);
        setHideInfos(false);
      }
    },
    [viewdBikes],
  );
  const [hideInfos, setHideInfos] = useState(false);
  const onTransitionStart = useCallback(() => {
    setHideInfos(true);
  }, []);
  const { width, isMobile, isPortrait } = useWidth();
  const containerWidth = useMemo(
    () => (isMobile ? width : 842),
    [isMobile, width],
  );
  const { title } = useTitle(currentBike);

  if (isLoading) {
    return <>Loading</>;
  }
  if ((bikes && bikes.length === 0) || !bikes || !currentBike) {
    return <></>;
  }
  return (
    <div className="w-full">
      <div className="max-w-page mx-auto px-[35px] md:px-4">
        <h2 className="text-[1.4rem] md:text-[2.8rem] leading-[1.8rem] md:leading-[2.8rem]">
          <Trans
            tOptions={{
              title: title,
              nr: String(
                bikes.findIndex((bike) => bike.id === currentBike.id) + 1,
              ),
              total: bikes.length,
            }}
            components={{
              red: <span className="text-primary" />,
            }}
          >
            caroussel_title
          </Trans>
          <br />«{title}»
        </h2>
      </div>
      {viewdBikes.length > 1 ? (
        <Swiper
          className="w-full h-[424px] md:h-[547px]"
          slidesPerView={
            isMobile
              ? (1 / containerWidth) * (2 * 35) + 1
              : window.innerWidth / (containerWidth + 59)
          }
          centeredSlides={true}
          onSliderFirstMove={onTransitionStart}
          onSlideChangeTransitionStart={onTransitionStart}
          onSlideChangeTransitionEnd={onSliderChange}
          onSlideChange={onSliderChange}
          spaceBetween={isMobile ? 15 : 59}
          loop={true}
        >
          <span slot="container-start" className="hidden md:inline">
            <SwiperButton direction="next" />
            <SwiperButton direction="prev" />
          </span>
          {viewdBikes.map((bike, index) => (
            <SwiperSlide
              key={index}
              className="w-full h-[424px] md:h-[547px] flex justify-center"
            >
              <Slide
                bike={bike}
                containerWidth={containerWidth}
                hideInfos={hideInfos}
                currentBike={bike.id === currentBike.id}
                portrait={isPortrait}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="px-[35px]">
          <Slide
            bike={currentBike}
            containerWidth={containerWidth - (isMobile ? 35 * 2 : 0)}
            hideInfos={hideInfos}
            currentBike={true}
            portrait={isPortrait}
          />
        </div>
      )}
      {
        <div
          className={`max-w-page mx-auto px-[35px] md:px-4 relative transition-opacity ease-linear ${hideInfos ? "opacity-0" : "opacity-100"}`}
        >
          <div className="flex flex-col">
            <Countdown
              end_date={currentBike.end_date}
              auction_start={currentBike.auction_start}
            />
            <div className="initial md:hidden">
              <Button variant="contained" href={{ url: `/${currentBike.id}` }}>
                {currentBike.is_biddable === true ? (
                  <Trans>bid</Trans>
                ) : (
                  <Trans>more</Trans>
                )}
              </Button>
            </div>
            <div className="mt-[15px] md:mt-0">
              <DataSheet bike={currentBike} />
            </div>
            <p className="mt-4 text-small">
              {
                currentBike[
                  getTranslationKey("description", i18n.resolvedLanguage)
                ]
              }
            </p>
          </div>
        </div>
      }
    </div>
  );
};
