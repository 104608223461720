import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Bike } from "../../redux/features/bike/bikeSlice";
import { InputWithLabel } from "../ui/InputWithLabel";
import { Button } from "../ui/Button";
import { useCreateBidMutation } from "../../redux/api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Bid, setBid } from "../../redux/features/bid/bidSlice";
import { Modal } from "../ui/Modal";
import { useLocation } from "wouter";
import { validateEmail } from "../../utils";
import CheckImage from "../../assets/images/check.svg";
import { linkPrivacyPolicy } from "../Footer";

interface BidForm {
  bid: { value: number; error?: string };
  name: { value: string; error?: string };
  first_name: { value: string; error?: string };
  mail: { value: string; error?: string };
  phone: { value: string; error?: string };
  address: { value: string; error?: string };
  zip: { value: number; error?: string };
  city: { value: string; error?: string };
  disclaimers: { value: boolean; error?: string };
}

const initialForm: BidForm = {
  bid: { value: 0 },
  name: { value: "" },
  first_name: { value: "" },
  mail: { value: "" },
  phone: { value: "" },
  address: { value: "" },
  zip: { value: 0 },
  city: { value: "" },
  disclaimers: { value: false },
};
export const BidForm = (props: { bike: Bike; updateBike: (bike) => void }) => {
  const { bike, updateBike } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [location, navigate] = useLocation();
  const [formData, setFormData] = useState<BidForm>(initialForm);
  const storedBid = useSelector((state: RootState) => state.bid.bid);

  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);

  const [
    updateBid,
    {
      isLoading: isLoadingUpdateBid,
      error: errorUpdateBid,
      data: responseUpdateBid,
    },
  ] = useCreateBidMutation();

  useEffect(() => {
    if (storedBid) {
      // Fill form from stred bid
      setFormData({
        first_name: { value: storedBid.first_name },
        name: { value: storedBid.name },
        address: { value: storedBid.address },
        zip: { value: storedBid.zip },
        city: { value: storedBid.city },
        mail: { value: storedBid.email },
        phone: { value: storedBid.phone },
        bid: { value: 0 },
        disclaimers: { value: false },
      });
    }
  }, [storedBid]);
  useEffect(() => {
    if (isLoadingUpdateBid) {
      setShowLoadingModal(true);
      return;
    }
    if (responseUpdateBid && responseUpdateBid.id) {
      navigate(`${location}/${responseUpdateBid.id}`);
    }
  }, [isLoadingUpdateBid, responseUpdateBid, location, navigate]);
  const onChange = useCallback(
    (key: string, value: string | number) => {
      setFormData({ ...formData, [key]: { value: value, error: undefined } });
    },
    [formData],
  );

  const onSubmitForm = useCallback(
    (event) => {
      event.preventDefault();
      //VALIDATE
      const isDirty: string[] = [];
      const min_bid = Math.max(
        Number(bike.highest_bid?.value || 0) + 10,
        bike.min_price,
      );
      const newData = { ...formData };
      [
        "first_name",
        "name",
        "mail",
        "phone",
        "bid",
        "zip",
        "address",
        "city",
      ].forEach((key) => {
        if (formData[key].value === "" || formData[key].value === 0) {
          const label = t(`form_input_label_${key}`);
          newData[key].error = t("form_error_not_empty", { label });
          isDirty.push(key);
        }
      });
      if (formData.bid.value < min_bid) {
        newData.bid.error =
          bike.highest_bid === null
            ? t("minPriceBid", { min_price: bike.min_price })
            : t("form_error_bid_too_low", { min_bid });
        isDirty.push("bid");
      }
      if (Number.isInteger(Number(formData.bid.value)) === false) {
        newData.bid.error = t("invalid_bid_float");
        isDirty.push("bid");
      }
      if (
        Number(formData.bid.value) >= 100000 ||
        isNaN(Number(formData.bid.value))
      ) {
        newData.bid.error = t("invalid_bid");
        isDirty.push("bid");
      }
      if (!validateEmail(formData.mail.value)) {
        newData.mail.error = t("invalid_email");
        isDirty.push("mail");
      }
      if (String(formData.phone.value).length <= 5) {
        newData.phone.error = t("invalid_phone");
        isDirty.push("phone");
      }
      if (formData.disclaimers.value === false) {
        newData.disclaimers.error = t("accept_disclaimers_error");
        isDirty.push("disclaimers");
      }
      // if(formData.mail.value !== EMAIL_FORMAT) isDirty = true;
      if (isDirty.length !== 0) {
        window.scrollTo({
          top: document.getElementById(`form-${isDirty[0]}`).offsetTop,
          left: 0,
          behavior: "smooth",
        });
        setFormData(newData);
      } else {
        const bid: Bid = {
          bike_id: bike.id,
          email: newData.mail.value,
          value: newData.bid.value,
          name: newData.name.value,
          first_name: newData.first_name.value,
          address: newData.address.value,
          zip: newData.zip.value,
          phone: newData.phone.value,
          city: newData.city.value,
        };
        dispatch(setBid(bid));
        updateBid(bid);
      }
    },
    [formData, updateBid, bike.id, bike.highest_bid, updateBike],
  );
  const onChangeDisclaimers = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFormData({
        ...formData,
        disclaimers: { value: Boolean(!formData.disclaimers.value) },
      });
    },
    [formData],
  );
  return (
    <div className="bg-white md:bg-light rounded md:rounded-md p-0 md:p-[40px]">
      <form onSubmit={onSubmitForm}>
        <div className="flex flex-row flex-wrap mx-[-10px]">
          <div className="w-full px-[10px] mb-8">
            <InputWithLabel
              inputKey="bid"
              type="number"
              label={t("form_input_label_bid")}
              value={formData.bid}
              placeholder={
                bike.highest_bid
                  ? `min. ${Math.round(Number(bike.highest_bid.value)) + 10} CHF`
                  : `min. ${Math.round(bike.min_price)} CHF`
              }
              onChange={onChange}
            />
            <div className="text-sm-small leading-[1rem] mt-[10px] text-black/40">
              <Trans>bidnote</Trans>
            </div>
          </div>
          <hr className="w-full mb-8 border-black opacity-30 border-[1px] mx-2" />
          <div className="w-full md:w-1/2 px-[10px] mb-4">
            <InputWithLabel
              inputKey="first_name"
              label={t("form_input_label_first_name")}
              value={formData.first_name}
              onChange={onChange}
            />
          </div>
          <div className="w-full md:w-1/2 px-[10px] mb-4">
            <InputWithLabel
              inputKey="name"
              label={t("form_input_label_name")}
              value={formData.name}
              onChange={onChange}
            />
          </div>
          <div className="w-full md:w-full px-[10px] mb-4">
            <InputWithLabel
              inputKey="address"
              label={t("form_input_label_address")}
              value={formData.address}
              onChange={onChange}
            />
          </div>
          <div className="w-full md:w-1/2 px-[10px] mb-4">
            <InputWithLabel
              inputKey="zip"
              type="number"
              label={t("form_input_label_zip")}
              value={formData.zip}
              onChange={onChange}
            />
          </div>
          <div className="w-full md:w-1/2 px-[10px] mb-4">
            <InputWithLabel
              inputKey="city"
              label={t("form_input_label_city")}
              value={formData.city}
              onChange={onChange}
            />
          </div>
          <div className="w-full md:w-1/2 px-[10px] mb-4">
            <InputWithLabel
              inputKey="mail"
              type="email"
              label={t("form_input_label_mail")}
              value={formData.mail}
              onChange={onChange}
            />
          </div>
          <div className="w-full md:w-1/2 px-[10px] mb-4">
            <InputWithLabel
              inputKey="phone"
              type="tel"
              label={t("form_input_label_phone")}
              value={formData.phone}
              onChange={onChange}
            />
          </div>
          <div
            className="w-full px-[10px] flex items-start "
            id="form-disclaimers"
          >
            <input
              value={String(formData.disclaimers.value)}
              onChange={onChangeDisclaimers}
              type="checkbox"
              id="accept-disclaimers"
              className="hidden peer"
            />
            <label htmlFor="accept-disclaimers" className="flex flex-row">
              <div className="flex-grow-0 mr-[20px]">
                <div
                  className={`w-[25px] h-[25px] bg-white rounded-[5px] flex items-center justify-center ${formData.disclaimers.error !== undefined ? "border-primary border-[2px]" : "border-[2px] border-black/20 md:border-0"}`}
                >
                  {formData.disclaimers.value && (
                    <img src={String(CheckImage)} />
                  )}
                </div>
              </div>
              <div className="flex-grow">
                <Trans
                  components={{
                    link_disclaimer: (
                      <a href="/disclaimers" className="underline" />
                    ),
                    link_privacy: (
                      <a
                        href={
                          linkPrivacyPolicy[
                            i18n.resolvedLanguage as "de" | "fr" | "it"
                          ]
                        }
                        className="underline"
                        target="_blank"
                      />
                    ),
                  }}
                >
                  accept_disclaimers
                </Trans>
                {formData.disclaimers.error && (
                  <>
                    <br />
                    <span className="text-primary text-sm-small md:text-small py-[10px]">
                      {formData.disclaimers.error}
                    </span>
                  </>
                )}
              </div>
            </label>
          </div>

          <div className="w-full flex px-[10px] mb-4 mt-[20px]">
            <div className="flex-grow" />
            <div>
              <Button
                href={{ url: "#" }}
                variant="contained"
                submit={t("form_submit_button")}
              />
            </div>
          </div>
        </div>
      </form>
      <Modal dismissable={false} show={showLoadingModal}>
        <Trans>Loading</Trans>
      </Modal>
    </div>
  );
};
