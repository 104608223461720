import React, { useCallback, useRef } from "react";

export const Modal = (
  props: React.PropsWithChildren<{
    dismissable: boolean;
    show: boolean;
    onCloseModal?: () => void;
    borderless?: boolean;
  }>,
) => {
  const { children, dismissable, show, onCloseModal } = props;
  const backdropRef = useRef<HTMLDivElement | null>(null);
  const handleOnClickBackdrop = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (dismissable && onCloseModal && backdropRef.current === e.target) {
        onCloseModal();
      }
    },
    [dismissable, onCloseModal, backdropRef],
  );
  if (show === false) {
    return <></>;
  }
  return (
    <div
      ref={backdropRef}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-[150]"
      onClick={handleOnClickBackdrop}
    >
      <div
        className={`max-w-page max-h-screen overflow-y-auto bg-white rounded md:rounded-md ${props.borderless ? "p-0 overflow-hidden" : "p-8"}`}
      >
        {children}
      </div>
    </div>
  );
};
