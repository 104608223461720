import React, { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useScrollToTop, useWidth } from "../../utils";
import { ArrowIcon } from "../ui/ArrowIcon";

export const FAQ = () => {
  const { t } = useTranslation("faq");
  useScrollToTop();
  const { isMobile, isTablet } = useWidth();
  const onBack = useCallback(() => {
    history.back();
  }, []);
  return (
    <>
      {!isMobile && !isTablet && (
        <div
          onClick={onBack}
          className="cursor-pointer px-[20px] md:px-[90px] h-auto xl:h-0 text-black hover:text-primary uppercase flex gap-2"
        >
          <ArrowIcon size={30} dir="backward" />
          <span className="text-btn3">
            <Trans>back</Trans>
          </span>
        </div>
      )}
      <div className="px-[35px] max-w-page-lg mx-auto mb-20">
        <h1 className="text-title mb-[30px]">FAQ</h1>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => {
          return (
            <>
              <h4>{t(`question_${i}`)}</h4>
              <p>{t(`answer_${i}`)}</p>
            </>
          );
        })}
      </div>
    </>
  );
};
