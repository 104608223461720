import { configureStore } from "@reduxjs/toolkit";
import { bikeReducer } from "./features/bike/bikeSlice";
import { uiReducer } from "./features/ui/uiSlice";
import { bidReducer } from "./features/bid/bidSlice";
import { api } from "./api";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  PAUSE,
  REHYDRATE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistBidConfig = {
  key: "bid",
  version: 1,
  storage,
};
const persistUIconfig = {
  key: "ui",
  version: 1,
  storage,
  whitelist: ["language", "cookieBannerAccepted"],
};
const persistedBidReducer = persistReducer(persistBidConfig, bidReducer);
const persistedUIReducer = persistReducer(persistUIconfig, uiReducer);
export const store = configureStore({
  reducer: {
    ui: persistedUIReducer,
    bike: bikeReducer,
    bid: persistedBidReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([api.middleware]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
