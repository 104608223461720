import React, { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useScrollToTop, useWidth } from "../../utils";
import { ArrowIcon } from "../ui/ArrowIcon";

export const Disclaimers = () => {
  const { t } = useTranslation("disclaimer");
  useScrollToTop();
  const { isMobile, isTablet } = useWidth();
  const onBack = useCallback(() => {
    history.back();
  }, []);
  return (
    <>
      {!isMobile && !isTablet && (
        <div
          onClick={onBack}
          className="cursor-pointer px-[20px] md:px-[90px] h-auto xl:h-0 text-black hover:text-primary uppercase flex gap-2"
        >
          <ArrowIcon size={30} dir="backward" />
          <span className="text-btn3">
            <Trans>back</Trans>
          </span>
        </div>
      )}
      <div className="px-[35px] max-w-page-lg mx-auto mb-20">
        <h1 className="text-title mb-[30px]">{t("title")}</h1>
        <h4>{t("section_1_title")}</h4>
        <p>{t("section_1_p_1")}</p>
        <p>{t("section_1_p_2")}</p>
        <p>{t("section_1_p_3")}</p>
        <h4>{t("section_2_title")}</h4>
        <p>{t("section_2_p_1")}</p>
        <p>{t("section_2_p_2")}</p>
        <p>{t("section_2_p_3")}</p>
        <p>{t("section_2_p_4")}</p>
        <h4>{t("section_3_title")}</h4>
        <p>{t("section_3_p_1")}</p>
        <p>{t("section_3_p_2")}</p>
        <h4>{t("section_4_title")}</h4>
        <p>{t("section_4_p_1")}</p>
        <h4>{t("section_5_title")}</h4>
        <p>{t("section_5_p_1")}</p>
        <p>{t("section_5_p_2")}</p>
        <h4>{t("section_6_title")}</h4>
        <p>{t("section_6_p_1")}</p>
        <p>{t("section_6_p_2")}</p>
        <p>{t("section_6_p_3")}</p>
        <p>{t("section_6_p_4")}</p>
        <h4>{t("section_7_title")}</h4>
        <p>{t("section_7_p_1")}</p>
        <h4>{t("section_8_title")}</h4>
        <p>{t("section_8_p_1")}</p>
        <p>{t("section_8_p_2")}</p>
        <h4>{t("section_9_title")}</h4>
        <p>{t("section_9_p_1")}</p>
        <p>{t("section_9_p_2")}</p>
      </div>
    </>
  );
};
