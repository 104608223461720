import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export enum VideoState {
  STOP = "stop",
  PLAY = "play",
}
export interface UIState {
  navbarHeight: 80 | 144;
  showBackButton: boolean;
  language: "de" | "fr" | "it";
  portalContainerReady: boolean;
  cookieBannerAccepted: boolean;
  documentTitle: string;
  scrollToSection: string | null;
  videoState: VideoState;
}

const initialState: UIState = {
  navbarHeight: 144,
  showBackButton: false,
  language: "de",
  portalContainerReady: false,
  cookieBannerAccepted: false,
  documentTitle: "Limmat-Bike",
  scrollToSection: null,
  videoState: VideoState.STOP,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setNavbarHeight: (state, action: PayloadAction<80 | 144>) => {
      state.navbarHeight = action.payload;
    },
    setBackButton: (state, action: PayloadAction<boolean>) => {
      state.showBackButton = action.payload;
    },
    setLanguage: (state, action: PayloadAction<"de" | "fr" | "it">) => {
      state.language = action.payload;
    },
    setPortalContainerReady: (state, action: PayloadAction<boolean>) => {
      state.portalContainerReady = action.payload;
    },
    setCookieBannerAccepted: (state, action: PayloadAction<boolean>) => {
      state.cookieBannerAccepted = action.payload;
    },
    setDocumentTitle: (state, action: PayloadAction<string>) => {
      state.documentTitle = action.payload;
    },
    setScrollToSection: (state, action: PayloadAction<string | null>) => {
      state.scrollToSection = action.payload;
    },
    setVideoState: (state, action: PayloadAction<VideoState>) => {
      state.videoState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNavbarHeight,
  setBackButton,
  setLanguage,
  setPortalContainerReady,
  setCookieBannerAccepted,
  setDocumentTitle,
  setScrollToSection,
  setVideoState,
} = uiSlice.actions;
export const uiReducer = uiSlice.reducer;
