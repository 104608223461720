import React from "react";
import { Trans } from "react-i18next";
import { BidCheckCodeResponse } from "../../redux/api";
import { Countdown } from "../ui/Countdown";
import { Button } from "../ui/Button";
import { useTitle } from "../../utils";

export const HighestBidder = (props: { response: BidCheckCodeResponse }) => {
  const { response } = props;
  const { title } = useTitle(response.bike);
  return response.highest_bidder ? (
    <>
      <h1 className="text-h1-sm md:text-h1 mt-0 mb-[30px]">
        <Trans tOptions={{ forename: response.forename ?? "-" }}>
          highest_bidder_title
        </Trans>
      </h1>
      <div className="bg-white md:bg-light rounded md:rounded-md p-0 md:px-[40px] md:pb-[40px]">
        {response.bike && (
          <>
            <Countdown
              auction_start={response.bike.auction_start}
              end_date={response.bike.end_date}
            />
            <div className="flex h-[198px] overflow-hidden rounded mb-[35px]">
              <div className="w-[198px] h-[198px]">
                <img
                  src={response.bike.images[0].bike_url}
                  className="w-full h-full object-cover object-center"
                />
              </div>
              <div className="bg-white flex-grow px-[44px] py-[25px]">
                <h3 className="text-btn md:text-h3 mt-0 mb-[25px]">
                  <Trans tOptions={{ bike: title }}>highest_bidder_bike</Trans>
                </h3>
                <p className="text-success">
                  <Trans
                    tOptions={{
                      value: Math.round(response.bike.highest_bid.value),
                    }}
                    components={{ bold: <span className="font-bold" /> }}
                  >
                    highest_bidder_your_bid
                  </Trans>
                </p>
              </div>
            </div>
          </>
        )}
        <p>
          <Trans>highest_bidder_text</Trans>
        </p>
        <div className="flex">
          <div>
            <Button variant="contained" href={{ url: "~/bikes" }}>
              <Trans>to_bikes</Trans>
            </Button>
          </div>
          <div />
        </div>
      </div>
    </>
  ) : (
    <div className="flex flex-col">
      <p>
        <Trans>bid_error</Trans>
      </p>
      <div className="flex">
        <div>
          <Button variant="contained" href={{ url: "/bikes" }}>
            <Trans>to_bikes</Trans>
          </Button>
        </div>
        <div />
      </div>
    </div>
  );
};
