import React, { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useScrollToTop, useWidth } from "../../utils";
import { setBackButton } from "../../redux/features/ui/uiSlice";
import { useDispatch } from "react-redux";
import { ArrowIcon } from "../ui/ArrowIcon";

export const Order = () => {
  const { t } = useTranslation("order");
  useScrollToTop();
  const { isMobile, isTablet } = useWidth();
  const onBack = useCallback(() => {
    history.back();
  }, []);
  return (
    <>
      {!isMobile && !isTablet && (
        <div
          onClick={onBack}
          className="cursor-pointer px-[20px] md:px-[90px] h-auto xl:h-0 text-black hover:text-primary uppercase flex gap-2"
        >
          <ArrowIcon size={30} dir="backward" />
          <span className="text-btn3">
            <Trans>back</Trans>
          </span>
        </div>
      )}
      <div className="px-[35px] max-w-page-lg mx-auto mb-20">
        <h1 className="text-title mb-[30px]">{t("title")}</h1>
        <p>{t("p_1")}</p>
        <ol>
          <li>{t("li_1")}</li>
          <li>{t("li_2")}</li>
          <li>{t("li_3")}</li>
          <li>{t("li_4")}</li>
          <li>
            <Trans
              ns="order"
              components={{
                link_sms: (
                  <a
                    href="https://smsup.ch"
                    className="text-primary underline"
                    target="_blank"
                  />
                ),
              }}
            >
              li_5
            </Trans>
          </li>
          <li>{t("li_6")}</li>
          <li>{t("li_7")}</li>
          <li>{t("li_8")}</li>
          <li>{t("li_9")}</li>
        </ol>
      </div>
    </>
  );
};
