import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { setDefaultOptions } from "date-fns";
import { getI18nLang } from "./utils";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "de",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      de: {
        translation:
          require("assets/translations/de/translation.json") as Record<
            string,
            string
          >,
        about: require("assets/translations/de/about.json") as Record<
          string,
          string
        >,
        faq: require("assets/translations/de/faq.json") as Record<
          string,
          string
        >,
        privacy_policy:
          require("assets/translations/de/privacy_policy.json") as Record<
            string,
            string
          >,
        disclaimer: require("assets/translations/de/disclaimer.json") as Record<
          string,
          string
        >,
        order: require("assets/translations/de/order.json") as Record<
          string,
          string
        >,
      },
      fr: {
        translation:
          require("assets/translations/fr/translation.json") as Record<
            string,
            string
          >,
        about: require("assets/translations/fr/about.json") as Record<
          string,
          string
        >,
        faq: require("assets/translations/fr/faq.json") as Record<
          string,
          string
        >,
        privacy_policy:
          require("assets/translations/fr/privacy_policy.json") as Record<
            string,
            string
          >,
        disclaimer: require("assets/translations/fr/disclaimer.json") as Record<
          string,
          string
        >,
        order: require("assets/translations/fr/order.json") as Record<
          string,
          string
        >,
      },
      it: {
        translation:
          require("assets/translations/it/translation.json") as Record<
            string,
            string
          >,
        about: require("assets/translations/it/about.json") as Record<
          string,
          string
        >,
        faq: require("assets/translations/it/faq.json") as Record<
          string,
          string
        >,
        privacy_policy:
          require("assets/translations/it/privacy_policy.json") as Record<
            string,
            string
          >,
        disclaimer: require("assets/translations/it/disclaimer.json") as Record<
          string,
          string
        >,
        order: require("assets/translations/it/order.json") as Record<
          string,
          string
        >,
      },
    },
  });

setDefaultOptions({ locale: getI18nLang(i18n.resolvedLanguage) });
export default i18n;
