import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface Bid {
  id?: string;
  value: number;
  name: string;
  first_name: string;
  email: string;
  phone: string;
  address: string;
  zip: number;
  city: string;
  bike_id: number;
  code?: number;
  is_valid?: boolean;
}
export interface BidState {
  bid: Bid | null;
}

const initialState: BidState = {
  bid: null,
};

export const bidSlice = createSlice({
  name: "bid",
  initialState,
  reducers: {
    setBid: (state, action: PayloadAction<Bid>) => {
      state.bid = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBid } = bidSlice.actions;
export const bidReducer = bidSlice.reducer;
