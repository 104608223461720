import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Bid } from "./features/bid/bidSlice";
import { REHYDRATE } from "redux-persist";
import { Action } from "redux";
import { RootState } from "./store";
import { Bike } from "./features/bike/bikeSlice";

function isHydrateAction(action: Action): action is Action<typeof REHYDRATE> & {
  key: string;
  payload: RootState;
  err: unknown;
} {
  return action.type === REHYDRATE;
}
export interface BidCheckCodeResponse {
  success: boolean;
  bike?: Bike;
  forename?: string;
  highest_bidder?: boolean;
  error?: string;
}
export interface BidUpdateResponse {
  success: boolean;
  id?: string;
  error?: string;
}
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1/",
    prepareHeaders: (headers, { getState }) => {
      const language = (getState() as RootState).ui.language;
      if (language) {
        headers.set("content-language", language);
      }
      return headers;
    },
  }),
  tagTypes: ["Bikes", "Bids"],
  // to prevent circular type issues, the return type needs to be annotated as any
  extractRehydrationInfo(action, { reducerPath }): any {
    if (isHydrateAction(action)) {
      // when persisting the api reducer
      if (action.key === "limmat-bike-bid") {
        return action.payload;
      }
    }
  },
  endpoints: (build) => {
    return {
      // Get all Bikes
      getBikes: build.query<Bike[], void>({
        query: () => "bikes",
        providesTags: (result) => [{ type: "Bikes" as const }],
      }),
      //Get Single Bike
      getBikeById: build.query<Bike, string>({
        query: (id) => `bikes/${id}`,
        providesTags: (result) => [{ type: "Bikes" as const, id: result.id }],
      }),
      // Create a new Bid
      createBid: build.mutation<BidUpdateResponse, Partial<Bid>>({
        query: ({ ...patch }) => ({
          url: `bids`,
          method: "PUT",
          body: patch,
        }),
        transformErrorResponse: (
          response: { status: string | number },
          meta,
          arg,
        ) => response.status,
      }),
      // Check for the Validation-Code
      checkCode: build.mutation<BidCheckCodeResponse, Partial<Bid>>({
        query: ({ ...patch }) => ({
          url: `check_bid`,
          method: "PUT",
          body: patch,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Bikes", id: result.bike?.id },
          { type: "Bikes" },
        ],
        transformErrorResponse: (
          response: { status: string | number },
          meta,
          arg,
        ) => response.status,
      }),
      // Get Time
      getTime: build.query<{ time: string; polling_interval: number }, void>({
        query: () => "time",
      }),
    };
  },
});

export const {
  useGetBikeByIdQuery,
  useLazyGetBikeByIdQuery,
  useGetBikesQuery,
  useCreateBidMutation,
  useCheckCodeMutation,
  useGetTimeQuery,
} = api;
