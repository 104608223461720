import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import IconCountdown from "../../assets/images/icon_alarm.svg";
import { intervalToDuration, isBefore, eachDayOfInterval } from "date-fns";
import { useGetTimeQuery } from "../../redux/api";

export const Countdown = (props: {
  end_date: string;
  auction_start: string;
  onCountdownFinish?: () => void;
}) => {
  const { end_date, auction_start } = props;
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState<{
    label: string;
    hasEnded: Boolean;
  }>({ label: "", hasEnded: true });
  const endDate = useMemo(() => new Date(end_date), [end_date]);

  useEffect(() => {
    const tick = () => {
      const now = new Date();
      const duration = intervalToDuration({ start: now, end: endDate });
      const daysCount = duration.days ?? 0;
      const days = daysCount > 0 ? `${daysCount}${t("date_days_short")} ` : "";
      const hours = duration.hours
        ? `${duration.hours}${t("date_hours_short")} `
        : "";
      const minutes = duration.minutes
        ? `${duration.minutes ?? 0}${t("date_min_short")} `
        : "";
      let seconds = "";
      if (daysCount === 0 && !duration.hours && (duration.minutes ?? 0) <= 3) {
        seconds = duration.seconds ? `${duration.seconds ?? 0}sek ` : "";
      }
      setCountdown({
        label: [days, hours, minutes, seconds].join(""),
        hasEnded: isBefore(endDate, now),
      });
    };
    tick();
    const interval = setInterval(tick, 1000);
    return () => clearInterval(interval);
  }, [endDate, t]);

  const auctionStart = useMemo(() => {
    if (isBefore(new Date(), new Date(auction_start))) {
      const now = new Date();
      const interval = { start: now, end: new Date(auction_start) };
      const duration = intervalToDuration(interval);
      const days = eachDayOfInterval(interval).length;
      return `${days}${t("date_days_short")} ${duration.hours}${t("date_hours_short")} ${duration.minutes}${t("date_min_short")}`;
    } else {
      return null;
    }
  }, [auction_start]);
  if (countdown.hasEnded) return <div className="h-[15px] md:[55pc]"></div>;
  return (
    <div className="text-sm-body2 md:text-btn flex items-center py-[20px]">
      <img
        src={String(IconCountdown)}
        className="mr-2 w-[18px] md:w-[22px]"
        alt="Alarm Icon"
      />
      <div className="pt-0.5">
        {auctionStart ? (
          <Trans
            tOptions={{ start_date: countdown.label }}
            components={{
              bold: <span className="ml-[6px] font-bold" />,
            }}
          >
            auction_start
          </Trans>
        ) : (
          <Trans
            tOptions={{ countdown: countdown.label }}
            components={{
              bold: <span className="ml-[6px] font-bold" />,
            }}
          >
            offer_ends_in
          </Trans>
        )}
      </div>
    </div>
  );
};
