import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface HighestBid {
  value: number;
  person: { name: string; forename: string; city: string };
}
export interface BikeImage {
  id: number;
  sort: number;
  bike_url: string;
}
export interface Bike {
  id: number;
  title: string;
  title_fr: string | null;
  title_it: string | null;
  description: string;
  description_fr: string;
  description_it: string;
  images: BikeImage[];
  images_portrait: BikeImage[];
  end_date: string;
  auction_start: string;
  highest_bid: HighestBid | null;
  sex: string;
  size: number;
  gears: number;
  weight: number;
  breaks: string;
  details: string;
  details_fr: string;
  details_it: string;
  is_biddable: boolean;
  bike_type: string;
  body_size: number;
  min_price: number;
}
export interface BikeState {
  bikes: Bike[];
}

const initialState: BikeState = {
  bikes: [],
};

export const bikeSlice = createSlice({
  name: "bike",
  initialState,
  reducers: {
    setBikes: (state, action: PayloadAction<Bike[]>) => {
      state.bikes = action.payload;
    },
    updateBike: (state, action: PayloadAction<Bike>) => {
      const bikeIndex = state.bikes.findIndex(
        (b) => b.id === action.payload.id,
      );
      if (bikeIndex) {
        state.bikes[bikeIndex] = action.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBikes, updateBike } = bikeSlice.actions;
export const bikeReducer = bikeSlice.reducer;
