import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { Link } from "wouter";
import { useWidth } from "../../utils";
import { BackIcon } from "../ui/BackIcon";
import { useGetBikesQuery } from "../../redux/api";

const Wrapper = (props: React.PropsWithChildren<{}>) => {
  const { isTablet } = useWidth();
  return isTablet ? (
    <div className="h-[56px] text-sm-body flex justify-between">
      {props.children}
    </div>
  ) : (
    <div className="flex justify-between">{props.children}</div>
  );
};

export const SideNav = (props: { currentBikeId: number }) => {
  const { data: bikes, error, isLoading } = useGetBikesQuery();
  const nav = useMemo(() => {
    if (bikes) {
      const currentIndex = bikes.findIndex(
        (bike) => bike.id === props.currentBikeId,
      );
      if (currentIndex === -1) {
        return null;
      }
      const next =
        bikes[currentIndex >= bikes.length - 1 ? 0 : currentIndex + 1].id;
      const prev =
        bikes[currentIndex <= 0 ? bikes.length - 1 : currentIndex - 1].id;
      return { next, prev };
    }
    return null;
  }, [bikes, props.currentBikeId]);

  return nav && bikes.length > 1 ? (
    <Wrapper>
      <Link
        href={`/${nav.prev}`}
        className="block md:fixed flex items-center gap-2 left-[35px] md:left-[25px] md:top-1/2 rotate-0 md:rotate-90 uppercase font-display origin-bottom-left text-black hover:text-primary"
      >
        <div className="md:hidden">
          <BackIcon size={18} />
        </div>
        <Trans>last_bike</Trans>
      </Link>
      <Link
        href={`/${nav.next}`}
        className="block md:fixed  flex items-center gap-2 right-[35px] md:right-[25px] md:top-1/2 md:-rotate-90 uppercase font-display origin-bottom-right text-black hover:text-primary"
      >
        <Trans>next_bike</Trans>
        <div className="md:hidden">
          <BackIcon dir="forward" size={18} />
        </div>
      </Link>
    </Wrapper>
  ) : (
    <></>
  );
};
