import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useParams } from "wouter";
import { Button } from "../ui/Button";
import { Trans, useTranslation } from "react-i18next";
import { Gallery } from "../modules/Gallery";
import { Section } from "../ui/Section";
import { Collapsible } from "../ui/Collapsible";
import { BidForm } from "../modules/BidForm";
import { Bike } from "../../redux/features/bike/bikeSlice";
import { Countdown } from "../ui/Countdown";
import { DataSheet } from "../modules/DataSheet";
import WWFLogo from "../../assets/images/WWF_Logo.svg";
import { SideNav } from "../modules/SideNav";
import { useDispatch, useSelector } from "react-redux";
import {
  setBackButton,
  setDocumentTitle,
  setScrollToSection,
} from "../../redux/features/ui/uiSlice";
import { getTranslationKey, useTitle, useWidth } from "../../utils";
import { RootState } from "../../redux/store";
import { useGetBikeByIdQuery, useGetTimeQuery } from "../../redux/api";
import sanitizeHtml from "sanitize-html";
import { ArrowIcon } from "../ui/ArrowIcon";

export const Detail = () => {
  const params = useParams<{ id: string }>();
  const bidFormRef = useRef<HTMLElement | null>(null);
  const { i18n, t } = useTranslation();
  const { data: time } = useGetTimeQuery(null, {
    pollingInterval: 1000 * 20,
  });
  const pollingInterval = useMemo(() => {
    return time ? time.polling_interval : 20;
  }, [time]);
  const { navbarHeight } = useSelector((state: RootState) => state.ui);
  const { data, error, isLoading } = useGetBikeByIdQuery(String(params.id), {
    pollingInterval: 1000 * pollingInterval,
  });
  const [bike, setBike] = useState<Bike | null>(null);
  const dispatch = useDispatch();
  const updateBike = useCallback((bike) => setBike(bike), []);
  const { isTablet, isMobile, isPortrait } = useWidth();
  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setScrollToSection("bikes"));
    if (data) {
      updateBike(data);
      dispatch(
        setDocumentTitle(t("document_title_detail", { bike_name: data.title })),
      );
    }
  }, [data, updateBike, dispatch, t]);
  const onScrollToBidForm = useCallback(() => {
    if (bidFormRef.current) {
      window.scrollTo({
        top: bidFormRef.current.offsetTop - navbarHeight - 20,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [bidFormRef, navbarHeight]);
  const isBiddable = useMemo(() => bike?.is_biddable ?? false, [bike]);
  const { title } = useTitle(bike);
  const [location] = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const onCountdownFinish = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <>
      {!isMobile && !isTablet && (
        <Link
          href="~/bikes"
          className="px-[20px] md:px-[90px] h-auto xl:h-0 text-black hover:text-primary uppercase flex gap-2"
        >
          <ArrowIcon size={30} dir="backward" />
          <span className="text-btn3">
            <Trans>back</Trans>
          </span>
        </Link>
      )}
      <div className="max-w-page mx-auto px-[35px] md:px-4">
        {!bike ? (
          <div>Loading</div>
        ) : (
          <div>
            <SideNav currentBikeId={Number(params.id)} />
            <Section id="info">
              <h2 className="text-sm-big leading-[1.8rem] md:leading-auto mt-[30px] md:mt-0 md:text-h2 md:leading-[2.25rem] md:mb-[13px]">
                <Trans>recycled</Trans>
                <br />«{title}»
              </h2>
              <div className="flex gap-2 my-4 items-end">
                {bike.highest_bid && (
                  <div className="whitespace-pre-wrap flex items-center flex-wrap px-[22px] py-[12px] md:pt-[10px] md:pb-[8px] border-[2px] border-black rounded md:rounded-md text-body md:text-btn">
                    {isBiddable === false ? (
                      <Trans
                        tOptions={{
                          value: Math.round(bike.highest_bid.value),
                          forename: bike.highest_bid.person.forename,
                          name: bike.highest_bid.person.name,
                          city: bike.highest_bid.person.city,
                        }}
                        components={{
                          bold: <span className="font-bold ml-1" />,
                        }}
                      >
                        sold
                      </Trans>
                    ) : (
                      <Trans
                        tOptions={{
                          value: Math.round(bike.highest_bid.value),
                          forename: bike.highest_bid.person.forename,
                          name: bike.highest_bid.person.name,
                          city: bike.highest_bid.person.city,
                        }}
                        components={{ bold: <span className="font-bold" /> }}
                      >
                        current_price
                      </Trans>
                    )}
                  </div>
                )}
                {isBiddable === true && (
                  <div className="hidden md:flex">
                    <Button onClick={onScrollToBidForm} variant="contained">
                      <Trans>bid</Trans>
                    </Button>
                  </div>
                )}
              </div>
              <Gallery
                images={bike[isPortrait ? "images_portrait" : "images"]}
              />

              <Countdown
                end_date={bike.end_date}
                auction_start={bike.auction_start}
                onCountdownFinish={onCountdownFinish}
              />
              {isBiddable == true && (
                <div className="md:hidden mb-4">
                  <Button variant="contained" onClick={onScrollToBidForm}>
                    <Trans>bid</Trans>
                  </Button>
                </div>
              )}
              <DataSheet bike={bike} />
              <p className="text-sm-body md:text-small mt-4">
                {bike[getTranslationKey("description", i18n.resolvedLanguage)]}
              </p>
            </Section>
            <Section id="technical">
              <Collapsible
                initialActive={null}
                items={[
                  {
                    id: 0,
                    title: t("technical_data"),
                    content: (
                      <div
                        className="data-sheet text-sm-body md:text-small mt-2"
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(
                            bike[
                              getTranslationKey(
                                "details",
                                i18n.resolvedLanguage,
                              )
                            ],
                          ),
                        }}
                      />
                    ),
                  },
                ]}
              />
            </Section>
            <Section id="bid" refProp={bidFormRef}>
              <div className="bg-light md:rounded-md mx-[-35px] md:mx-0 px-[35px] py-[30px] md:p-[40px] md:pt[40px]">
                {isBiddable && (
                  <h1 className="text-sm-h1 md:text-h1 leading-[2rem] md:leading-[2.8rem] mb-[13px]">
                    <Trans tOptions={{ title }}>form_title</Trans>
                  </h1>
                )}
                <p className="mb-4 text-sm-body md:text-small">
                  <Trans>form_info</Trans>
                </p>
                <div className="flex items-center bg-white rounded md:rounded-md font-light text-sm-small md:text-small p-[24px] mt-[20px]">
                  <img
                    className="w-[46px] h-[64px] ml-[17px] mr-[34px]"
                    src={String(WWFLogo)}
                    alt="WWF Logo"
                  />
                  <Trans>bid_wwf_short</Trans>
                </div>
              </div>
            </Section>
            {isBiddable === true && (
              <Section id="bid">
                <BidForm bike={bike} updateBike={updateBike} />
              </Section>
            )}
          </div>
        )}
      </div>
    </>
  );
};
