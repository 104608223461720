import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import LimmatBikeLg from "../../assets/images/Limmatbike_1318x622.svg";
import LimmatBikeMd from "../../assets/images/Limmatbike_858x790.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import IconPlay from "../../assets/images/icon_play.svg";
import { Trans } from "react-i18next";
import { useWidth } from "../../utils";
import YouTube from "react-youtube";
import { setVideoState, VideoState } from "../../redux/features/ui/uiSlice";
import { Video } from "../ui/Video";

export const Hero = () => {
  const { navbarHeight, videoState } = useSelector(
    (state: RootState) => state.ui,
  );
  const [height, setHeight] = useState<number>(window.innerHeight);
  const { isMobile, isTablet } = useWidth();
  const dispatch = useDispatch();

  useEffect(() => {
    const onResize = () => {
      setHeight(window.innerHeight);
    };
    addEventListener("resize", onResize);
    return () => {
      removeEventListener("resize", onResize);
    };
  }, []);
  useLayoutEffect(() => {
    const onScroll = () => {
      dispatch(setVideoState(VideoState.STOP));
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [dispatch]);
  const onClickPlay = useCallback(() => {
    if (videoState === VideoState.STOP) {
      dispatch(setVideoState(VideoState.PLAY));
    }
  }, [videoState, dispatch]);

  return (
    <div
      className="flex justify-center items-center relative px-2 md:px-[20px] min-h-[580px] h-screen md:h-auto"
      style={{ marginTop: isMobile ? -navbarHeight : "auto" }}
    >
      <div
        onClick={onClickPlay}
        className={`absolute ${videoState === VideoState.PLAY ? "z-[99]" : "z-video"} cursor-pointer w-full h-full 4xl:max-w-[1098px] 3xl:max-w-[1048px] 2xl:max-w-[855px] xl2:max-w-[808px] lg:max-w-[716.4px] md:max-w-[734px] 4xl:max-h-[581px] 3xl:max-h-[555px] 2xl:max-h-[452px] xl2:max-h-[428px] lg:max-h-[379.4px] md:max-h-[388px] flex justify-center items-center md:rounded-md backdrop-blur overflow-hidden`}
      >
        {videoState === VideoState.PLAY ? (
          <Video />
        ) : (
          <>
            <button
              className="text-[46.5px] md:text-btn2 text-primary uppercase flex items-center z-10  my-auto"
              onClick={onClickPlay}
            >
              <Trans>Play</Trans>{" "}
              <img
                className="h-[46.5px] md:h-[55px] mt-[-7px] md:mt-[-10px]"
                src={String(IconPlay)}
                alt="Play button"
              />
            </button>
            {isMobile ? (
              <img
                src="/images/240311_OSP_LimmatBike_Website Video Mobile.gif"
                className="absolute object-cover object-center w-full h-full opacity-80"
                alt="Hero Bike"
              />
            ) : (
              <video
                className="absolute object-cover object-center w-full h-full opacity-80"
                loop={true}
                autoPlay={true}
                muted={true}
              >
                <source
                  src="/images/240311_OSP_LimmatBike_Website_Video.mp4"
                  type="video/mp4"
                />
                <source
                  src="/images/240311_OSP_LimmatBike_Website_Video.webm"
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>
            )}
          </>
        )}
      </div>
      <img
        style={{ height: height - navbarHeight - 20 }}
        className="object-contain hidden md:inline 4xl:max-w-[1854px] 2xl:max-w-[1475px] xl2:max-w-[1207px] xl:max-w-[1104px]"
        src={isTablet ? String(LimmatBikeMd) : String(LimmatBikeLg)}
        alt="Limmatbike"
      />
      <img
        className="object-contain object-top mt-[63px] inline md:hidden z-[96] pointer-events-none"
        src="/images/limmatbike-mobile.png"
        alt="Limmat Bike"
      />
    </div>
  );
};
