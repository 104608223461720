import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { de, fr, it } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { Bike } from "./redux/features/bike/bikeSlice";
export const getI18nLang = (locale: string) => {
  switch (locale) {
    case "fr":
      return fr;
    case "it":
      return it;
    default:
      return de;
  }
};

export const getTranslationKey = (key: string, lang: string = "de") => {
  switch (lang) {
    case "it":
      return `${key}_it`;
    case "fr":
      return `${key}_fr`;
    default:
      return key;
  }
};
export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
};

export const useWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  useLayoutEffect(() => {
    const resize = () => {
      const width = window.innerWidth;
      setWidth(width);
      setIsMobile(width < 600);
      setIsTablet(width > 600 && width < 1024);
      setIsPortrait(window.innerWidth < window.innerHeight);
    };
    resize();
    addEventListener("resize", resize);
    return () => {
      removeEventListener("resize", resize);
    };
  }, []);
  return { width, isMobile, isTablet, isPortrait };
};
export const useTitle = (bike?: Bike) => {
  const { language } = useTranslation().i18n;
  const title = useMemo(() => {
    if (bike) {
      switch (language) {
        case "fr":
          return bike.title_fr ?? bike.title;
        case "it":
          return bike.title_it ?? bike.title;
        default:
          return bike.title;
      }
    } else {
      return "";
    }
  }, [language, bike]);
  return { title };
};
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
