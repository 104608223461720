import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { setBackButton } from "../../redux/features/ui/uiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "wouter";
import { Modal } from "../ui/Modal";
import { useCheckCodeMutation } from "../../redux/api";
import { RootState } from "../../redux/store";
import VerificationInput from "react-verification-input";
import { HighestBidder } from "../modals/HighestBidder";

export const ConfirmBid = () => {
  const [code, setCode] = useState<string>("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams<{ id: string; bid: string }>();
  const storedBid = useSelector((state: RootState) => state.bid.bid);

  const [
    checkBid,
    {
      isLoading: isLoadingCheckBid,
      error: errorCheckBid,
      data: responseCheckBid,
    },
  ] = useCheckCodeMutation();
  useEffect(() => {
    dispatch(setBackButton(false));
  }, [dispatch]);
  const onChangeCode = useCallback((input) => {
    setCode(input);
  }, []);
  const onSubmitCode = useCallback(
    (event) => {
      event.preventDefault();
      const bidWithCodeAndId = {
        ...storedBid,
        id: params.bid,
        code: Number(code),
      };
      checkBid(bidWithCodeAndId);
    },
    [params.bid, code, checkBid, storedBid],
  );
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (responseCheckBid?.success === false) {
      setShowError(true);
    }
  }, [responseCheckBid]);
  const phone = useMemo(() => {
    const phoneArray = String(storedBid.phone).split("");
    return phoneArray
      .map((value, index) => (index > phoneArray.length - 4 ? value : "*"))
      .join("");
  }, [storedBid.phone]);
  const onCloseError = useCallback(() => {
    setShowError(false);
  }, []);
  return (
    <div className="max-w-page mx-auto px-[35px] md:px-4">
      <h1 className="text-h1-sm md:text-h1">
        <Trans>confirm_phone_title</Trans>
      </h1>
      <div className="bg-white md:bg-light rounded md:rounded-md mt-[30px] p-0 md:p-[50px]">
        <p className="mb-[40px]">
          <Trans
            tOptions={{ phone }}
            components={{ bold: <span className="font-bold" /> }}
          >
            confirm_phone_text
          </Trans>
        </p>
        <div>
          <form onSubmit={onSubmitCode} noValidate>
            <VerificationInput
              validChars={"z0-9"}
              value={code}
              placeholder=""
              onChange={onChangeCode}
              classNames={{
                container: "h-[58px] md:gap-[25px] mb-[30px]",
                character:
                  "bg-white rounded md:rounded-md md:min-w-[50px] border-black/20 border-[2px] md:border-none flex justify-center items-center pt-[4px]",
                characterInactive: "",
                characterSelected:
                  "text-black outline-black outline-offset-[-2px]",
                characterFilled: "",
              }}
            />
            <div className="flex gap-4 flex-col-reverse md:flex-row mb-[50px] md:mb-0">
              <div>
                <Button
                  href={{ url: `/${params.id}` }}
                  variant="contained-dark"
                >
                  <Trans>back</Trans>
                </Button>
              </div>
              <div>
                <Button
                  href={{ url: "#" }}
                  variant="contained"
                  submit="Senden"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal dismissable={false} show={isLoadingCheckBid}>
        <Trans>loading</Trans>
      </Modal>
      {responseCheckBid && (
        <>
          <Modal
            dismissable={false}
            show={!isLoadingCheckBid && responseCheckBid.success}
          >
            {responseCheckBid.error ? (
              <p>
                <Trans>generic_error</Trans>
                <span>{responseCheckBid.error}</span>
              </p>
            ) : (
              <HighestBidder response={responseCheckBid} />
            )}
          </Modal>
          <Modal
            dismissable={true}
            onCloseModal={onCloseError}
            show={showError}
          >
            {responseCheckBid.error && (
              <p>
                <span>{responseCheckBid.error}</span>
              </p>
            )}
            <Button onClick={onCloseError} variant="contained">
              <Trans>Ok</Trans>
            </Button>
          </Modal>
        </>
      )}
    </div>
  );
};
