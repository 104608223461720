import React from "react";
import { Link } from "wouter";
type ButtonProps = React.PropsWithChildren<{
  href?: { url: string; target?: "_blank" };
  onClick?: (event: React.MouseEvent) => void;
  variant:
    | "contained"
    | "contained-dark"
    | "outlined"
    | "text"
    | "contained-black";
  submit?: string;
}>;
export const Button = (props: ButtonProps) => {
  const { href, children, submit, onClick, variant } = props;

  let classNameColors = "";
  switch (variant) {
    case "contained":
      classNameColors = "text-white bg-primary hover:bg-primary-dark";
      break;
    case "contained-dark":
      classNameColors = "text-black bg-white hover:bg-light";
      break;
    case "contained-black":
      classNameColors = "text-white bg-black";
      break;
    case "outlined":
      classNameColors = "border-[2px] border-black text-black";
      break;
    default:
      classNameColors = "text-primary";
  }
  const className = `flex justify-center items-center text-sm-btn md:text-btn uppercase py-[8px] py-[12px] px-[10px] md:px-[22px] rounded md:rounded-md font-display w-full whitespace-nowrap ${classNameColors}`;

  if (submit) {
    return <input className={className} type="submit" value={submit}></input>;
  }
  if (href) {
    return href.target ? (
      <a className={className} href={href.url} target={href.target}>
        {children}
      </a>
    ) : (
      <Link className={className} href={href.url}>
        {children}
      </Link>
    );
  }
  if (onClick) {
    return (
      <button className={className} onClick={onClick}>
        {children}
      </button>
    );
  }
  return <></>;
};
