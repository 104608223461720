import React, { useEffect, useRef } from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Routes } from "./components/Routes";
import { CookieBanner } from "./components/modals/CookieBanner";
import "./i18n";

let persistor = persistStore(store);
export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className="flex flex-col min-h-screen">
          <Navbar />
          <Routes />
          <Footer />
          <CookieBanner />
        </div>
      </PersistGate>
    </Provider>
  );
}
