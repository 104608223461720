import React, { useCallback, useMemo, useState } from "react";
import IconChevron from "../../assets/images/icon_chevron_down.svg";
const CallapsibleItem = (
  props: React.PropsWithChildren<{
    id: number;
    title: string;
    active: boolean;
    onClickTitle: (id: number) => void;
  }>,
) => {
  const { title, active, children, onClickTitle, id } = props;
  const handleClickTitle = useCallback(() => {
    onClickTitle(id);
  }, [onClickTitle, id]);
  return (
    <>
      <hr className="border-[1.5px] border-black/20" />{" "}
      <div
        className="flex flex-col overflow-hidden"
        style={{ height: active ? "auto" : 85 }}
      >
        <div
          className="h-[85px] flex flex-row items-center border-t-3 border-black items-center"
          onClick={handleClickTitle}
        >
          <h3 className="text-btn md:text-h3">{title}</h3>
          <div className="ml-4">
            <img
              className={`transition mt-[10px] md:mt-[8px] ease-in-out w-[24px] md:w-[40px] ${active ? "rotate-180" : ""}`}
              src={String(IconChevron)}
              alt="up or down"
            />
          </div>
        </div>
        <div className="border-b-3 border-black text-sm-body md-text-body">
          {children}
        </div>
      </div>
      <hr className="border-[1.5px] border-black/20" />
    </>
  );
};
export const Collapsible = (props: {
  initialActive: number | null;
  items: { id: number; title: string; content: React.ReactNode }[];
}) => {
  const [active, setActive] = useState<number | null>(props.initialActive);
  const onExpandItem = useCallback(
    (id: number) => {
      setActive(active === id ? null : id);
    },
    [active],
  );
  return (
    <div>
      {props.items.map((item) => (
        <CallapsibleItem
          key={item.id}
          id={item.id}
          title={item.title}
          active={item.id === active}
          onClickTitle={onExpandItem}
        >
          {item.content}
        </CallapsibleItem>
      ))}
    </div>
  );
};
