import React from "react";

export const ArrowIcon = (props: {
  size?: number;
  dir?: "forward" | "backward";
  stroke?: number;
}) => {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 39 39"
      className={props.dir === "backward" ? "rotate-180" : ""}
      width={props.size ?? 39}
      height={props.size ?? 39}
    >
      <path
        strokeWidth={props.stroke ?? 3}
        stroke="currentColor"
        d="M0,19.5l37.4,0L0,19.5z M37.4,19.5L19.4,1.6L37.4,19.5z M37.4,19.5L19.4,37.4L37.4,19.5z"
      />
      <path
        strokeWidth={props.stroke ?? 3}
        stroke="currentColor"
        d="M0,19.5l37.4,0 M37.4,19.5L19.4,1.6 M37.4,19.5L19.4,37.4"
      />
      <line
        stroke="currentColor"
        x1="17.6"
        y1="19.5"
        x2="37.4"
        y2="19.5"
        strokeWidth={props.stroke ?? 3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
