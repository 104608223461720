import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/features/ui/uiSlice";
import { RootState } from "../../redux/store";
import { bool } from "prop-types";

const Item = (props: {
  label: string;
  active: boolean;
  language: "de" | "fr" | "it";
}) => {
  const dispatch = useDispatch();
  const changeLanguage = useCallback(() => {
    i18n.changeLanguage(props.language);
    dispatch(setLanguage(props.language));
  }, [props.language, dispatch]);
  return (
    <li
      onClick={changeLanguage}
      className={`font-display text-sm-btn3 md:text-btn3 text-black hover:underline uppercase ${props.active ? "underline text-primary" : ""}`}
    >
      {props.label}
    </li>
  );
};
export const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { language } = useSelector((state: RootState) => state.ui);
  const [expanded, setExpanded] = useState<boolean>(false);
  const mobileMenu = useRef<HTMLUListElement | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (language !== i18n.resolvedLanguage) {
      i18n.changeLanguage(language);
      dispatch(setLanguage(language));
    }
  }, [i18n, language, dispatch]);
  const onClickAnywhere = useCallback(
    (evt: Event) => {
      evt.preventDefault();
      if (!mobileMenu.current?.contains(evt.target as Node)) {
        setExpanded(false);
        document.removeEventListener("mousedown", onClickAnywhere);
      }
    },
    [expanded, mobileMenu],
  );
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const onExpand = useCallback(() => {
    if (expanded === false) {
      setExpanded(true);
      document.addEventListener("mousedown", onClickAnywhere);
    } else {
      setExpanded(false);
      document.removeEventListener("mousedown", onClickAnywhere);
    }
  }, [expanded, onClickAnywhere]);

  const list = useMemo((): {
    label: string;
    key: string;
    active: boolean;
  }[] => {
    return [
      { label: "DE", key: "de", active: language === "de" },
      { label: "FR", key: "fr", active: language === "fr" },
      { label: "IT", key: "it", active: language === "it" },
    ];
  }, [language]);

  return (
    <>
      <div className="flex flex-column md:hidden">
        <ul
          onClick={onExpand}
          ref={mobileMenu}
          className={` ${expanded ? "h-auto" : "h-[32px]"} overflow-hidden`}
        >
          {[...list]
            .sort((lhs, rhs) => {
              return lhs.active === rhs.active ? 0 : lhs.active ? -1 : 1;
            })
            .map((li) => (
              <Item
                key={li.key}
                label={li.label}
                language={li.key as "de" | "fr" | "it"}
                active={li.active}
              />
            ))}
        </ul>
      </div>
      <ul className="hidden md:flex flex-row gap-4 font-display text-btn3 mr-4  mt-[9px]">
        {list.map((li) => (
          <Item
            key={li.key}
            label={li.label}
            language={li.key as "de" | "fr" | "it"}
            active={li.active}
          />
        ))}
      </ul>
    </>
  );
};
