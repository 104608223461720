import React, { useCallback, useMemo } from "react";
import { Button } from "../ui/Button";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setCookieBannerAccepted } from "../../redux/features/ui/uiSlice";

export const CookieBanner = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { cookieBannerAccepted } = useSelector((state: RootState) => state.ui);
  const onClickAccept = useCallback(() => {
    dispatch(setCookieBannerAccepted(true));
  }, [dispatch]);
  const linkUrl = useMemo(() => {
    switch (i18n.resolvedLanguage) {
      case "it":
        return "https://www.ochsnersport.ch/it/shop/cp/unternehmen/protezione-die-dati.html";
      case "fr":
        return "https://www.ochsnersport.ch/fr/shop/cp/unternehmen/protection-des-donnes.html";
      default:
        return "https://www.ochsnersport.ch/de/shop/cp/unternehmen/datenschutz.html";
    }
  }, [i18n.resolvedLanguage]);
  return cookieBannerAccepted === false ? (
    <div className="fixed bottom-0 w-full z-[100]">
      <div className="max-w-container mx-auto flex flex-col md:flex-row gap-4 items-center pt-[20px] pb-[30px] md:py-[34px] px-[35px] md:px-[50px] bg-light rounded-t md:rounded-t-md">
        <div className="grow">
          <p className="text-tiny md:text-sm-small">
            <Trans
              components={{
                url: (
                  <a
                    href={linkUrl}
                    target="_blank"
                    className="text-black underline"
                  />
                ),
              }}
            >
              cookie_disclaimers
            </Trans>
          </p>
        </div>
        <div className="w-full md:w-auto">
          <Button variant="contained-black" onClick={onClickAccept}>
            <Trans>accept</Trans>
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
