import React from "react";

export const BackIcon = (props: {
  size?: number;
  dir?: "forward" | "backward";
}) => (
  <svg
    className={props.dir === "forward" ? "rotate-180" : ""}
    width={props.size ?? 30}
    height={props.size ?? 30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.3906 15.1481L1.99947 15.1481L29.3906 15.1481ZM1.99947 15.1481L15.1472 28.2959L1.99947 15.1481ZM1.99947 15.1481L15.1472 2.00038L1.99947 15.1481Z"
      fill="currentColor"
    />
    <path
      d="M29.3906 15.1481L1.99947 15.1481M1.99947 15.1481L15.1472 28.2959M1.99947 15.1481L15.1472 2.00038"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinejoin="round"
    />
  </svg>
);
