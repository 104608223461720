import React, { useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Trans } from "react-i18next";
import SwiperCore from "swiper";
import { Pagination } from "swiper/modules";
import WWFLogo from "../../assets/images/WWF_Logo.svg";

export const StoryCaroussel = (props: {
  items: { image: string; text: string; title: string }[];
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const { items } = props;
  const onSliderChange = useCallback((swiper) => {
    setCurrentSlide(swiper.realIndex);
  }, []);
  SwiperCore.use([Pagination]);

  return (
    <div className="relative">
      <Swiper
        className="w-full  mb-[-26px]"
        slidesPerView={1.2}
        spaceBetween={16}
        pagination={{
          clickable: true,
        }}
        slidesOffsetAfter={34}
        slidesOffsetBefore={34}
        onSlideChange={onSliderChange}
        autoHeight={true}
      >
        {items.map((item, index) => {
          return (
            <SwiperSlide key={index} className={`flex flex-col h-auto`}>
              <div className="rounded md:rounded-md w-full overflow-hidden">
                <img
                  className="object-cover w-full h-full"
                  src={`/images/story/${item.image}`}
                />
              </div>
              <h2
                className={`text-sm-h2 transition-opacity ease-in-out ${currentSlide === index ? "opacity-100" : "opacity-0"}`}
              >
                <Trans>{item.title}</Trans>
              </h2>
              <p
                className={`text-sm-body2 transition-opacity ease-in-out ${currentSlide === index ? "opacity-100" : "opacity-0"}`}
              >
                <Trans>{item.text}</Trans>
              </p>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="px-[35px]">
        <div className="flex bg-light rounded md:rounded-md font-light text-sm-small items-center px-[24px] py-[15px] mt-[20px]">
          <img
            className="w-[38px] h-[57.29px] ml-[17px] mr-[34px]"
            src={String(WWFLogo)}
            alt="WWF Logo"
          />
          <Trans>story_wwf</Trans>
        </div>
      </div>
    </div>
  );
};
