import React, { useMemo } from "react";
import { Bike } from "../../redux/features/bike/bikeSlice";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
type BikeType = "m" | "f" | "c" | "r";
const getBikeType = (type: BikeType, sex: "m" | "f" | "u") => {
  switch (type) {
    case "m":
      return i18n.t("data_type_m");
    case "f":
      return i18n.t("data_type_f");
    case "r":
      return i18n.t(`data_type_r_${sex}`);
    case "c":
      return i18n.t(`data_type_c_${sex}`);
    default:
      return type;
  }
};

export const DataSheet = (props: { bike: Bike }) => {
  const { bike } = props;
  const { t } = useTranslation();
  const dataSheet = useMemo((): { label: string; value: string }[] => {
    if (bike) {
      return [
        { label: t("data_size"), value: String(bike.size) + " cm" },
        { label: t("data_bodysize"), value: String(bike.body_size) + " cm" },
        { label: t("data_gears"), value: String(bike.gears) },
        { label: t("data_weight"), value: `${t("circa")} ${bike.weight} kg` },
      ];
    }
    return [];
  }, [bike, t]);
  return (
    <div className="bg-dark text-white text-sm-body md:text-body rounded md:rounded-md flex flex-col md:flex-row p-5 justify-evenly">
      {dataSheet.map((d, index) => (
        <div key={`data-${index}`} className="flex flex-row md:flex-col">
          <div className="mr-2">{d.label}:</div>
          <div className="font-bold">{d.value}</div>
        </div>
      ))}
    </div>
  );
};
