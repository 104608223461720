import ReactOnRails from "react-on-rails";

import App from "../App";
import "../assets/styles/application";
import { store } from "../redux/store";

ReactOnRails.setOptions({
  traceTurbolinks: false,
});
ReactOnRails.register({
  App,
});
ReactOnRails.registerStore({
  store,
});
