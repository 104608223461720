import React from "react";
import { Redirect, Route, Switch, useParams } from "wouter";
import { Home } from "./pages/Home";
import { Detail } from "./pages/Detail";
import { About } from "./pages/About";
import { FAQ } from "./pages/FAQ";
import { Impressum } from "./pages/Impressum";
import { Disclaimers } from "./pages/Disclaimers";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { ConfirmBid } from "./pages/ConfirmBid";
import { Order } from "./pages/Order";

export const Routes = () => {
  return (
    <main className="flex-grow">
      <Switch>
        <Route path="/bikes" nest>
          <Route path="/" component={Home} />
          <Route path="/:id" component={Detail} />
          <Route path="/:id/:bid" component={ConfirmBid} />
        </Route>
        <Route path="/about" component={About} />
        <Route path="/faq" component={FAQ} />
        <Route path="/impressum" component={Impressum} />
        <Route path="/disclaimers" component={Disclaimers} />
        <Route path="/privacy_policy" component={PrivacyPolicy} />
        <Route path="/order" component={Order} />
        {/* Default route in a switch */}
        <Route path="/">
          <Redirect to="/bikes" />
        </Route>
        <Route>404: Seite nicht gefunden</Route>
      </Switch>
    </main>
  );
};
