import React from "react";

export const Section = (
  props: React.PropsWithChildren<{
    id: string;
    refProp?: React.Ref<HTMLElement>;
  }>,
) => (
  <section id={props.id} className="mb-10" ref={props.refProp}>
    {props.children}
  </section>
);
