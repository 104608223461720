import React, { useEffect, useState, useTransition } from "react";
import { useWidth } from "../../utils";
import { useTranslation } from "react-i18next";
const videos = {
  landscape: {
    de: "/videos/240305_OSP_LB_LANGFILM_ABGABE_16x9_DE_3.mp4",
    fr: "/videos/240313_OSP_LB_LANGFILM_ABGABE_16x9_FR_3.mp4",
    it: "/videos/240312_OSP_LB_LANGFILM_ABGABE_16x9_IT_3.mp4",
  },
  portrait: {
    de: "/videos/240305_OSP_LB_LANGFILM_ABGABE_9x16_DE_3.mp4",
    fr: "/videos/240313_OSP_LB_LANGFILM_ABGABE_9x16_FR_3.mp4",
    it: "/videos/240312_OSP_LB_LANGFILM_ABGABE_9x16_IT_3.mp4",
  },
};
const videosWebM = {
  landscape: {
    de: "/videos/240305_OSP_LB_LANGFILM_ABGABE_16x9_DE.webm",
    fr: "/videos/240313_OSP_LB_LANGFILM_ABGABE_16x9_FR_2.webm",
    it: "/videos/240312_OSP_LB_LANGFILM_ABGABE_16x9_IT_2.webm",
  },
  portrait: {
    de: "/videos/240305_OSP_LB_LANGFILM_ABGABE_9x16_DE.webm",
    fr: "/videos/240313_OSP_LB_LANGFILM_ABGABE_9x16_FR_2.webm",
    it: "/videos/240312_OSP_LB_LANGFILM_ABGABE_9x16_IT_2.webm",
  },
};
export const Video = () => {
  const { i18n } = useTranslation();
  const { isMobile } = useWidth();

  return (
    <video
      className="object-cover w-full h-full"
      controls={true}
      autoPlay={true}
    >
      <source
        src={
          videos[isMobile ? "portrait" : "landscape"][
            i18n.resolvedLanguage as "de" | "fr" | "it"
          ]
        }
        type="video/mp4"
      />
      <source
        src={
          videosWebM[isMobile ? "portrait" : "landscape"][
            i18n.resolvedLanguage as "de" | "fr" | "it"
          ]
        }
        type="video/webm"
      />
      Your browser does not support the video tag.
    </video>
  );
};
