import React, { useEffect } from "react";
import {
  setBackButton,
  setDocumentTitle,
} from "../../redux/features/ui/uiSlice";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

export const About = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setDocumentTitle(t("about")));
  }, [dispatch, t]);
  return (
    <div className="px-[35] max-w-page-lg mx-auto">
      <h2>
        <Trans>about</Trans>
      </h2>
      <p>
        Salutantibus vitae elit libero, a pharetra augue. Ab illo tempore, ab
        est sed immemorabili. Magna pars studiorum, prodita quaerimus. Qui
        ipsorum lingua Celtae, nostra Galli appellantur. Prima luce, cum quibus
        mons aliud consensu ab eo.
      </p>
    </div>
  );
};
