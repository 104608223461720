import React, { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import WWFLogo from "../../assets/images/WWF_Logo.svg";
import { ArrowIcon } from "../ui/ArrowIcon";
export const Story = (props: {
  items: { image: string; text: string; title: string }[];
}) => {
  const [currentStory, setCurrentStory] = useState(0);
  const { items } = props;
  const { t } = useTranslation();
  const storyData = [
    {
      title: t(items[0].title),
      imageUrl: items[0].image,
      text: t(items[0].text),
      wwf: false,
      offset: [-313, -235],
      scrollSpeed: 1,
      width: 453,
      height: 278,
    },
    {
      title: t(items[1].title),
      imageUrl: items[1].image,
      text: t(items[1].text),
      wwf: false,
      offset: [-178, -198],
      scrollSpeed: 2,
      width: 453,
      height: 278,
    },
    {
      title: t(items[2].title),
      imageUrl: items[2].image,
      text: t(items[2].text),
      wwf: false,
      offset: [-143, -92],
      scrollSpeed: -1,
      width: 453,
      height: 278,
    },
    {
      title: t(items[3].title),
      imageUrl: items[3].image,
      text: t(items[3].text),
      wwf: false,
      offset: [-258, -51],
      scrollSpeed: -2,
      width: 453,
      height: 278,
    },
  ];
  const onNext = useCallback(() => {
    setCurrentStory(currentStory + 1);
  }, [currentStory]);
  const onPrev = useCallback(() => {
    setCurrentStory(currentStory - 1);
  }, [currentStory]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="max-w-container mx-auto px-4 mb-[228px]" ref={containerRef}>
      <div className="flex flex-col lg:flex-row items-start  min-h-[590px]">
        <div className="w-full lg:w-1/2 relative h-[540px]">
          {storyData.map((item, index) => {
            return (
              <div
                key={index}
                className="absolute left-1/2 top-1/2"
                style={{
                  zIndex:
                    index === currentStory
                      ? 50 + storyData.length
                      : 50 + storyData.length - index,
                  transform: `translate(${item.offset[0]}px, ${item.offset[1] + 73}px)`,
                  width: item.width,
                  height: item.height,
                }}
              >
                <img
                  src={`/images/story/${item.imageUrl}`}
                  className="w-full h-full object-cover object-center  rounded md:rounded-md "
                />
              </div>
            );
          })}
        </div>
        <div className="mt-[180px] lg:mt-0 w-full lg:w-1/2 flex flex-col md:pl-[104px]">
          <div className="flex flex-col justify-end h-[273px]">
            <h2 className="text-h2">
              <Trans>{storyData[currentStory].title}</Trans>
            </h2>
            <div className="">
              <p className="mb-0">{storyData[currentStory].text}</p>
            </div>
          </div>
          <div className="flex flex-col h-[124px] justify-center">
            <div className="max-w-[160px] flex justify-between text-primary">
              <button
                className={currentStory === 0 ? "opacity-20" : "opacity-100"}
                disabled={currentStory === 0}
                onClick={onPrev}
              >
                <ArrowIcon dir="backward" />
              </button>
              <button
                className={
                  currentStory === storyData.length - 1
                    ? "opacity-20"
                    : "opacity-100"
                }
                disabled={currentStory === storyData.length - 1}
                onClick={onNext}
              >
                <ArrowIcon />
              </button>
            </div>
          </div>
          <div className="flex bg-light rounded md:rounded-md font-light text-small p-[24px]">
            <img
              className="w-[38px] h-[57.29px] ml-[17px] mr-[34px]"
              src={String(WWFLogo)}
              alt="WWF Logo"
            />
            <Trans>story_wwf</Trans>
          </div>
        </div>
      </div>
    </div>
  );
};
