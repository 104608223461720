import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "../ui/Button";

export const Service = () => {
  const { language } = useTranslation().i18n;
  const link = useMemo(() => {
    switch (language) {
      case "it":
        return "https://www.ochsnersport.ch/it/shop/cp/beratung-tipps/bike/serviziobike.html";
      case "fr":
        return "https://www.ochsnersport.ch/fr/shop/cp/beratung-tipps/bike/servicebike.html";
      default:
        return "https://www.ochsnersport.ch/de/shop/cp/beratung-tipps/bike/bikeservice.html";
    }
  }, [language]);
  return (
    <div className="bg-black flex flex-col-reverse md:flex-row text-white px-4 md:px-4 lg:px-[120px] pt-[140px] pb-[68px]">
      <div className="flex flex-col justify-center items-start mr-0 md:mr-[-81px] z-10">
        <h2 className="text-sm-h2 md:text-h2">
          <Trans>service_title</Trans>
        </h2>
        <p className="text-sm-body2 md:text-body">
          <Trans>service_description</Trans>
        </p>
        <div className="mt-4 w-full md:w-auto">
          <Button
            variant="contained-dark"
            href={{
              url: link,
              target: "_blank",
            }}
          >
            <Trans>service</Trans>
          </Button>
        </div>
      </div>
      <div className="flex justify-center items-center max-w-[706px] mb-[-116px] md:mb-0">
        <div className="relative h-[342px] w-auto md:w-[707px] ml-[-80px] md:ml-0">
          <img
            src="/images/service.jpg"
            className="object-cover w-full h-full rounded md:rounded-md"
            alt="Ochsner Sport Service"
          />
        </div>
      </div>
    </div>
  );
};
