import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  setBackButton,
  setDocumentTitle,
} from "../../redux/features/ui/uiSlice";
import { useDispatch } from "react-redux";

export const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const ns = "privacy_policy";
  const { t } = useTranslation(ns);
  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setDocumentTitle(t("title")));
  }, [dispatch, t]);
  return (
    <div className="px-[35] max-w-page-lg mx-auto mb-20">
      <h1 className="text-title mb-[30px]">
        <Trans ns={ns}>title</Trans>
      </h1>
      <h2 className="text-subtitle">
        <Trans ns={ns}>section_1_title</Trans>
      </h2>
      <p>
        <Trans ns={ns}>section_1_p_1</Trans>
      </p>
      <p>
        <Trans ns={ns}>section_1_p_2</Trans>
      </p>
      <p>
        <Trans ns={ns}>section_1_p_3</Trans>
      </p>
      <h2 className="text-subtitle">
        <Trans ns={ns}>section_2_title</Trans>
      </h2>
      <p>
        <Trans ns={ns}>section_2_p_1</Trans>
      </p>
      <p>
        <Trans ns={ns}>section_2_p_2</Trans>
      </p>
      <p>
        <Trans ns={ns}>section_2_p_3</Trans>
      </p>
    </div>
  );
};
