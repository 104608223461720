import React, { useCallback, useEffect, useState } from "react";
import { BikeImage } from "../../redux/features/bike/bikeSlice";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import SwiperCore from "swiper";
import { Pagination } from "swiper/modules";
import { useWidth } from "../../utils";
import { ArrowIcon } from "../ui/ArrowIcon";
import SwiperClass from "swiper/types/swiper-class";

const SwiperButton = (props: { direction: "next" | "prev" }) => {
  const swiper = useSwiper();
  const onSwiperNext = useCallback(() => {
    if (swiper) {
      if (props.direction === "next") {
        swiper.slideNext();
      }
      if (props.direction === "prev") {
        swiper.slidePrev();
      }
    }
  }, [swiper, props.direction]);
  return (
    <button
      onClick={onSwiperNext}
      className={`text-white hidden md:flex absolute top-1/2 mt-[-28.2px] ${props.direction === "next" ? "right-[30px]" : "left-[30px] rotate-180"} z-[90] bg-black/20 hover:bg-black/60 w-[56.4px] h-[56.4px] justify-center items-center rounded-full backdrop-blur-lg`}
    >
      <ArrowIcon size={30} />
    </button>
  );
};

interface GalleryProps {
  images: BikeImage[];
}
export const Gallery = (props: GalleryProps) => {
  const { images } = props;
  const { isMobile } = useWidth();
  const [swiper, setSwiper] = useState<SwiperClass>(null);
  SwiperCore.use([Pagination]);
  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0, 0);
    }
  }, [images, swiper]);
  return (
    <>
      {images.length === 1 ? (
        <div className="overflow-hidden rounded md:rounded-md w-full flex">
          <img className="w-full h-auto" src={images[0].bike_url} />
        </div>
      ) : (
        <div className="mx-[-35px] md:mx-0">
          <Swiper
            className={"w-full h-[424px] md:h-[547px]"}
            slidesPerView={isMobile ? 1.2 : 1}
            pagination={{
              clickable: true,
            }}
            loop={true}
            initialSlide={0}
            onSwiper={setSwiper}
            spaceBetween={isMobile ? 16 : 0}
            slidesOffsetAfter={isMobile ? 34 : 0}
            slidesOffsetBefore={isMobile ? 34 : 0}
          >
            <SwiperButton direction="next" />
            <SwiperButton direction="prev" />
            {images.map((image) => {
              return (
                <SwiperSlide key={image.id} className="rounded md:rounded-md">
                  <img
                    className="object-cover object-center w-full h-full rounded md:rounded-md"
                    src={image.bike_url}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
    </>
  );
};
