import React, { useEffect, useRef } from "react";
import { Link } from "wouter";
import Logo from "../assets/images/Logo_Ochsner_Sport.svg";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { LanguageSelector } from "./modules/LanguageSelector";
import {
  setNavbarHeight,
  setPortalContainerReady,
  VideoState,
} from "../redux/features/ui/uiSlice";
import { useWidth } from "../utils";
import { ArrowIcon } from "./ui/ArrowIcon";
export const Navbar = () => {
  const { navbarHeight, showBackButton, documentTitle, videoState } =
    useSelector((state: RootState) => state.ui);
  const { isMobile, isTablet } = useWidth();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isMobile) {
      dispatch(setNavbarHeight(80));
    } else {
      dispatch(setNavbarHeight(144));
    }
    document.title = documentTitle;
  }, [dispatch, isMobile, documentTitle]);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (buttonRef.current) {
      dispatch(setPortalContainerReady(true));
    }
  }, [buttonRef, dispatch]);
  return (
    <nav
      style={{ height: navbarHeight }}
      className="w-full xxl:max-w-[2380px] mx-auto z-navbar px-[20px] md:px-[90px] flex justify-between items-center pointer-events-none"
    >
      <div
        className={`pointer-events-auto ${videoState === VideoState.PLAY ? "opacity-0" : "opacity-100"} md:opacity-100`}
      >
        <Link href="/">
          <img
            className="h-[35px] md:h-[58.5px]"
            src={String(Logo)}
            alt="Ochsner Sport"
          />
        </Link>
      </div>
      <div className="pointer-events-auto relative flex flex-col md:flex-row-reverse items-end md:items-start h-[36px] md:h-auto">
        <div ref={buttonRef} id="header-button" />
        <LanguageSelector />
      </div>
    </nav>
  );
};
